import {useEffect, useState} from "react";
import {CircularProgress} from "@mui/material";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {auth} from "./config/firebase";
import routes from "./config/routes";
import Center from "./components/utils/Center";
import AuthChecker from "./components/auth/AuthChecker";
import Sidebar from "./components/navigationBar/NavigationBar";
import {UserProvider} from "./hooks/useUser.tsx";

function App() {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        auth.onAuthStateChanged((user) => {
            if (user) {
                console.info("User detected.");
            } else {
                console.info("No user detected");
            }
            setLoading(false);
        });
    }, []);

    if (loading)
        return (
            <Center>
                <CircularProgress/>
            </Center>
        );

    return (
        <div>
            <BrowserRouter basename={"/"}>
                <Routes>
                    {routes.map((route, index) => (
                        <Route
                            key={index}
                            path={route.path}
                            element={
                                route.protected ? (
                                    <AuthChecker>
                                        <UserProvider>
                                        <Sidebar/>
                                            <div className={"lg:ml-72 flex-1 min-h-screen bg-gray-50"}>
                                                <route.component/>
                                            </div>
                                        </UserProvider>
                                    </AuthChecker>
                                ) : (
                                <route.component/>
                                )
                            }
                        />
                    ))}
                </Routes>
            </BrowserRouter>
        </div>
    );
}

export default App;
