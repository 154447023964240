import {createContext, ReactNode, useCallback, useContext, useEffect, useState} from 'react';
import {getAuth} from "firebase/auth";

interface UserData {
    id: string;
    email: string;
    fullName: string;
    plan: string;
    createdAt: string;
}

interface UserContextType {
    userData: UserData | null;
    loading: boolean;
    error: string | null;
    refetchUser: () => Promise<void>;
}

const UserContext = createContext<UserContextType | undefined>(undefined);

export const UserProvider = ({children}: { children: ReactNode }) => {
    const user = getAuth();
    const [userData, setUserData] = useState<UserData | null>(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);

    const fetchUserData = useCallback(async () => {
        if (!user) {
            console.log("fetchin2g")
            setUserData(null);
            setLoading(false);
            return;
        }


        setLoading(true);
        try {
            const idToken = await user.currentUser?.getIdToken();
            const response = await fetch(`${import.meta.env.VITE_APP_API_URL}/user`, {
                headers: {
                    'Authorization': `Bearer ${idToken}`,
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data: UserData = await response.json();
            setUserData(data);
            setError(null);
        } catch (err) {
            setError(err instanceof Error ? err.message : 'Failed to fetch user data');
            setUserData(null);
        } finally {
            setLoading(false);
        }
    },[user]);

    useEffect(() => {
        fetchUserData();
    }, [fetchUserData, user]);

    const value = {
        userData,
        loading,
        error,
        refetchUser: fetchUserData
    };

    return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export const useUser = () => {
    const context = useContext(UserContext);
    if (context === undefined) {
        throw new Error('useUser must be used within a UserProvider');
    }
    console.log(context.userData)
    return context;
};
