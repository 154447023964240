import  { useEffect } from 'react';

export const Policy = () => {
    useEffect(() => {
        // Function to initialize Termly script
        const initTermly = () => {
            const script = document.createElement('script');
            script.innerHTML = `
        (function(d, s, id) {
          var js, tjs = d.getElementsByTagName(s)[0];
          if (d.getElementById(id)) return;
          js = d.createElement(s); js.id = id;
          js.src = "https://app.termly.io/embed-policy.min.js";
          tjs.parentNode.insertBefore(js, tjs);
        }(document, 'script', 'termly-jssdk'));
      `;

            // Append the script to the document
            document.body.appendChild(script);
        };

        // Initialize Termly
        if (!document.getElementById('termly-jssdk')) {
            initTermly();
        }

        // Cleanup
        return () => {
            const script = document.getElementById('termly-jssdk');
            if (script) {
                script.remove();
            }
        };
    }, []);

    return (
        <div className="w-full">
            <div
                data-name="termly-embed"
                data-id={"c176ebf4-207f-4261-b7c9-abad5af6aef5"}
                className="w-full min-h-[600px]"
            />
        </div>
    );
};

