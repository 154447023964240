import Login from "../screens/Login";
import {CVOptimizer} from '../screens/optimizer/Optimizer';
import {Dashboard} from "../screens/dashboard/Dashboard";
import {Optimizations} from "../screens/optimizations/Optimizations";
import {Pricing} from "../screens/pricing/Pricing";
import {CVList,} from "../screens/my-cvs/MyCVs";
import Template1 from "../screens/templates/Template1";
import { UserProfile } from "../screens/settings/Settings";
import { HelpCenter } from "../screens/help-center/HelpCenter";
import {Policy} from "../legal/PrivacyPolicy.tsx";
import {LandingPage} from "../landing/LandingPage.tsx";

interface RouteType {
    path: string;
    component: any;
    name: string;
    protected: boolean;
}

const routes: RouteType[] = [
    {
        path: "/",
        component: CVOptimizer,
        name: "Home Screen",
        protected: true,
    },
    {
        path: "optimizations",
        component: Optimizations,
        name: "Optimizations",
        protected: true,
    },
    {
        path: "/login",
        component: Login,
        name: "Login Screen",
        protected: false,
    },
    {
        path: "/dashboard",
        component: Dashboard,
        name: "Dashboard",
        protected: true,
    },
    {
        path: "/templates",
        component: Template1,
        name: "Dashboard",
        protected: true,
    },
    {
        path: "/pricing",
        component: Pricing,
        name: "Pricing",
        protected: false,
    },
    {
        path: "/my-cvs",
        component: CVList,
        name: "My CVs",
        protected: true,
    },
    {
        path: "/settings",
        component: UserProfile,
        name: "Settings",
        protected: true,
    },
    {
        path: "/help",
        component: HelpCenter,
        name: "Help Center",
        protected: true,
    },
    {
        path: "/policy",
        component: Policy,
        name: "Privacy Policy",
        protected: false,
    },
    {
        path: "/landing",
        component: LandingPage,
        name: "Landing Page",
        protected: false,
    },
];

export default routes;
